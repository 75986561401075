import axios from "axios";

export const sendForm = async(fromData : Object)=>{
    try {
        return await axios.postForm(`${process.env.REACT_APP_API_NDIAY_URL}/InsertionFormulaire.php`,
            {"Colonne" : fromData}
        );
    } catch {
        return null
    }
}