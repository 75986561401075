import { faCheckCircle, faInfoCircle, faPaperPlane, faRotateLeft, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Alert, Box, Button, Card, CardCover, FormControl, Input, Option, Select, Stack, Typography } from "@mui/joy"
import { COLOR, DEMANDE_FICHE, IMAGE } from "../../constants/constants"
import { Divider, InputLabel } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { FormNumberContext } from "../../context/FormNumberContext"
import { GET_FICHE_ENTETE_T, GET_FICHE_INFO_T, LOADING_STATE } from "../../constants/objectTye"
import { getFicheInfo } from "../../functions/API/Ndiay/getFicheInfo"
import { getFicheEntete } from "../../functions/API/Ndiay/getFicheEntete"
import { sendForm } from "../../functions/API/Ndiay/sendForm"

const FormModel = () => {
    const [ficheInfo, setficheInfo] = useState(null as GET_FICHE_INFO_T | null);
    const [loadingState, setloadingState] = useState(null as LOADING_STATE);
    const [ficheEntete, setficheEntete] = useState([] as GET_FICHE_ENTETE_T[]);
    const [formData, setformData] = useState({} as any);

    const { formNumber, setformNumber } = useContext(FormNumberContext);

    useEffect(
        () => {
            if (formNumber != null) {
                const { codeFeuille } = DEMANDE_FICHE[formNumber];
                getFicheInfo(codeFeuille).then(
                    data => {
                        if (data) {
                            setficheInfo(data);
                            setformData({
                                Table_Feuille: data.classeur[0].Table,
                                Date_Insertion: (new Date).toISOString().slice(0, 19).replace('T', ' '),
                                Login : 'Site-web' ,
                                LG : 2,
                                LT : 2,
                            });
                        }
                    }
                ).catch(
                    () => {
                        setloadingState("Chargement echoue");
                    }
                );
            }
        },
        [formNumber]
    );

    useEffect(
        () => {
            ficheInfo
                && getFicheEntete(ficheInfo.classeur[0].Code_Feuille).then(
                    data => {
                        data && setficheEntete(data);
                    }
                ).catch(
                    () => {
                        setloadingState("Chargement echoue");
                    }
                );
        },
        [ficheInfo]
    )

    useEffect(
        () => {
            switch (loadingState) {
                case "Chargement echoue":
                    alert("Une erreur est survenue");
                    window.location.reload(); break;

                // case "Chargement reussit":
                //     alert("Données soumise avec succes");
                //     window.location.reload(); break;

            }
        },
        [loadingState]
    );

    const submitForm = async () => {
        try {
            setloadingState("En cours de chargement");
            const fieldList = Object.keys(formData);
            if (
                ficheEntete.find(value =>
                    value.requis
                    && !fieldList.includes(value.nomColonne)
                )
            ) {
                alert("Veuillez fournir toutes les données");
            }

            const res = await sendForm(formData);

            setloadingState("Chargement reussit");
        } catch (error) {
            return false;
        }

    }

    return (
        formNumber != null && formNumber >= 0
            ? (
                <Stack
                    direction={"row"}
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        background: "rgba(29, 28, 28, 0.706)",
                        backdropFilter: "blur(10px)",
                        alignItems: ficheEntete && ficheEntete.length <= 4 ? "center" : "initial",
                        justifyContent: "center",
                        zIndex: 9999
                    }}
                >
                    <Stack
                        bgcolor={COLOR.lightGrey}
                        p={1}
                        borderRadius={15}
                        gap={2}
                        width={"calc(100% - 50px)"}
                        maxWidth={500}
                        minWidth={300}
                        sx={{
                            overflowY: 'scroll',
                            my: 2
                        }}
                    >
                        <Stack
                            width={30}
                            minWidth={30}
                            height={30}
                            minHeight={30}
                            bgcolor={COLOR.red}
                            justifyContent={"center"}
                            borderRadius={10}
                            color={COLOR.white}
                            alignSelf={"flex-end"}
                            onClick={() => { setformNumber(null) }}
                            zIndex={1}
                            sx={{
                                cursor: "pointer"
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Stack>

                        <Stack
                            direction={"row"}
                            gap={2}
                            mt={-5}
                        >
                            <Card
                                sx={{
                                    width: 50,
                                    minWidth: 50,
                                    height: 50,
                                    borderRadius: 0
                                }}
                            >
                                <CardCover>
                                    <img src={IMAGE.defaultImage} />
                                </CardCover>
                            </Card>
                            <Box
                                alignSelf={"flex-end"}
                            >
                                <Typography
                                    level="h4"
                                >
                                    {`Formulaire de ${DEMANDE_FICHE[formNumber]?.nom.toLowerCase()}`}
                                </Typography>

                                <Typography gap={1} level="body-sm">
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                    <Typography> Les champs dotés d'un * sont obligatoirs</Typography>
                                </Typography>
                            </Box>
                        </Stack>

                        <Divider />

                        {
                            ficheEntete && ficheEntete.map(value => (
                                !(
                                    [
                                        'LG',
                                        'LT',
                                        'Date_Insertion',
                                        'Login',
                                    ].includes(value.libelle)
                                )
                                && (
                                    value.choix
                                        ? (
                                            <select
                                                name={value.nomColonne}
                                                defaultValue={value.choix[0]}
                                                onChange={({ target }) => {
                                                    setformData((prev: any) => ({
                                                        ...prev,
                                                        [value.nomColonne]: target.value
                                                    }));
                                                }}
                                            >
                                                {
                                                    value.choix.map((currentChoix, index) => (
                                                        <option key={index} value={currentChoix}>{currentChoix}</option>
                                                    ))
                                                }
                                            </select>
                                        )
                                        : (
                                            <FormControl>
                                                <InputLabel>{`${value.libelle} ${value.requis ? '*' : ''}`}</InputLabel>
                                                <Input
                                                    name={value.nomColonne}
                                                    placeholder={value.libelle}
                                                    value={formData?.[value.nomColonne]}
                                                    onChange={({ target }) => {
                                                        setformData((prev: any) => ({
                                                            ...prev,
                                                            [value.nomColonne]: target.value
                                                        }));
                                                    }}
                                                    type={value.inputType || "text"}
                                                />
                                            </FormControl>
                                        )
                                )
                            ))
                        }

                        <Button
                            sx={{
                                ":hover": {
                                    bgcolor: COLOR.darKblue
                                }
                            }}
                            endDecorator={
                                <FontAwesomeIcon icon={
                                    loadingState == "Chargement reussit"
                                        ? faCheckCircle
                                        : faPaperPlane
                                } />
                            }
                            fullWidth
                            onClick={() => submitForm()}
                            loading={loadingState == "En cours de chargement"}
                        >
                            {
                                loadingState != "Chargement reussit" && 'Soumettre'
                            }
                        </Button>
                    </Stack>
                </Stack>
            )
            : null
    )
}

export default FormModel