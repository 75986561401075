import axios from "axios";
import { GET_FICHE_INFO_T } from "../../../constants/objectTye";

export const getFicheInfo = async(codeFueille : number)=>{
    try {
        const {data} : 
        {data: GET_FICHE_INFO_T} = await axios.get(`${process.env.REACT_APP_API_NDIAY_URL}/feuille.php?Code_Feuille=${codeFueille}`);
        return data;
    } catch {
        return null
    }
}